











































































































import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import { InputSetups } from "@/mixins/input-setups";
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";
import LanguageFilterHandlerModuleBuilder from '@/includes/logic/Modules/modules/modules/LanguageFilterHandlerModule/LanguageFilterHandlerModuleBuilder';

import ModuleConfigSettings from '../../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '../../../components/ModuleConfigSettingsWrapper.vue'
import FilterJournal from "../../../components/filters/FilterJournal.vue";
import FilterCommonSettings from "../../../components/filters/FilterCommonSettings.vue";
import FilterNewUsersSettings from "../../../components/filters/FilterNewUsersSettings.vue";
import FilterRemoveEditedSetting from "../../../components/filters/FilterRemoveEditedSetting.vue";
import FilterExtraSettings from "../../../components/filters/FilterExtraSettings.vue";
import FilterResetPunishSettings from '../../../components/filters/FilterResetPunishSettings.vue'
import FilterSettingLayout from "../../../components/filters/layout/FilterSettingLayout.vue";

import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue';
import BlackWhiteListInput from 'piramis-base-components/src/components/Pi/fields/BlackWhiteListInput/BlackWhiteListInput.vue';
import { ChipsInputTabs } from "piramis-base-components/src/components/Pi/fields/ChipsInput/types";

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      ChipsInputTabs
    }
  },
  components: {
    FilterSettingLayout,
    FilterExtraSettings,
    FilterRemoveEditedSetting,
    FilterResetPunishSettings,
    FilterNewUsersSettings,
    FilterCommonSettings,
    FilterJournal,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    NumberInput,
    BlackWhiteListInput
  },
})
export default class LanguageFilterHandlerModule extends Mixins(ModuleBindings, InputSetups, SelectOptionsMixin) {
  @VModel() module!: LanguageFilterHandlerModuleBuilder

  get tags() {
    return [ ...this.module.tariffTags() || [], ...this.module.tags() || [], ]
  }

  get filteredFilterLanguages() {
    return this.filterLanguages
    // .filter(l => !this.module.model.params.languages.items.some(i => i.includes(l.value)))
  }

  mounted() {
    if (this.module.tariffTags()) {
      this.$baseTemplate.saveButton.hide()
    } else {
      this.$baseTemplate.saveButton.show()
    }
  }
}
